// Angular
import { InjectionToken, TransferState, makeStateKey } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// Apollo
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { authLink, createPersitedLink, createSocketLink, emptyLink, errorLink } from './apollo.link.js';
import { environment } from '@awread/global/environments';
import { UniversalService } from '@awread/kernel/services';
import hashes from '../../../../../persisted-query-ids/client.json';

const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<any>('apollo.state');

export const apolloProvider = [
  {
    provide: APOLLO_CACHE,
    useValue: new InMemoryCache(),
  },
  {
    provide: APOLLO_OPTIONS,
    useFactory(httpLink: HttpLink, cache: InMemoryCache, universalService: UniversalService, transferState: TransferState, document: Document) {
      const isBrowserAndHaveCache = transferState.hasKey<any>(STATE_KEY);
      if (isBrowserAndHaveCache) {
        const state = transferState.get<any>(STATE_KEY, null);
        // console.log(`${STATE_KEY} | STATE_KEY:`, isBrowserAndHaveCache, state);
        cache.restore(state);
      } else {
        // console.log(`${STATE_KEY} | STATE_KEY: no cache here, start doing this on server`, transferState);
        // debugger;
        transferState.onSerialize(STATE_KEY, () => {
          // console.log('cache.extract();', cache.extract());
          return cache.extract();
        });
        // Reset cache after extraction to avoid sharing between requests
        cache.reset();
      }

      // console.warn('do we create persist link?', environment.production)
      // const loggerLink = new ApolloLink((operation, forward) => {
      //   console.log('Request:', operation);
      //   return forward(operation).map((response) => {
      //     console.log('Response:', response);
      //     return response;
      //   });
      // });

      // console.log("YOUR HTML document.location", document.location.origin, 'uri', document.location.origin + environment.graphqlEndpoint)

      const serverGraphqlEndpoint = (environment.production ? document.location.origin : 'http://localhost:3000') + environment.graphqlEndpoint;

      const myHttpLink = httpLink.create({
        includeExtensions: true,
        uri: universalService.isBrowser() ? environment.graphqlEndpoint : serverGraphqlEndpoint,
      });

      return {
        link: ApolloLink.from([
          errorLink,
          authLink,
          // createPersitedLink(hashes),
          // PERSIST QUERY 2
          environment.production ? createPersitedLink(hashes) : emptyLink,
          universalService.isBrowser() ? createSocketLink(myHttpLink) : myHttpLink,
          // loggerLink,
        ]),
        cache,
        // ssrForceFetchDelay: 100,
        ssrMode: universalService.isServer(),
      };
    },
    deps: [HttpLink, APOLLO_CACHE, UniversalService, TransferState, DOCUMENT],
  },
];
